<template>
  <div
    class="modal modal__confirm"
    id="insertCustomFieldModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--upper">
              {{ uiProps.customFieldSetName }}
            </span>
            <a
              data-dismiss="#insertCustomFieldModal"
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              ref="close"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
        </div>
        <div class="modal__content">
          <div class="modal__head border-bottom">
            <h5 class="modal__head__title text--capital">Add Field</h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Field Name</label>
                <div class="error--text" v-if="!uiProps.errors.field.valid">
                  {{ uiProps.errors.field.message }}
                </div>
                <select class="select select--lg" v-model="form.customFieldKey">
                  <option value="null" disabled selected>
                    Select Field Name
                  </option>
                  <option
                    v-for="item in uiProps.customFields"
                    :key="item.fieldKey"
                    :value="item.fieldKey"
                  >
                    {{ item.fieldName }}
                  </option>
                </select>
              </div>

              <div class="form__item">
                <label class="form__label">Type</label>
                <p class="text--regular text--bold text--black text--capital">
                  {{ uiProps.customFieldType }}
                </p>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    @click="confirmSave"
                    type="button"
                    class="button form__button form__button--lg w-30 save-changes"
                  >
                    Add Field
                  </button>
                  <a
                    ref="close"
                    data-dismiss="#insertCustomFieldModal"
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function initialState() {
  return {
    form: { customFieldKey: "null" },
    uiProps: {
      errors: {
        field: { valid: true, message: "" },
      },
      customFields: [],
      customFieldSetName: "",
      customFieldType: "",
    },
    confirm: false,
    process: false,
  };
}
export default {
  props: {
    account: Object,
    customFields: Array,
    customFieldSet: Object,
    loadingCustomFieldSets: Boolean,
  },
  data: function () {
    return initialState();
  },
  watch: {
    "form.customFieldKey": function (change) {
      if (change) {
        this.resetErrors();
        const customField = this.uiProps.customFields.find(
          (item) => item.fieldKey == change
        );
        this.uiProps.customFieldType = customField.dataType;
      }
    },
    customFieldSet(change) {
      if (change) {
        this.uiProps.customFieldSetName = change.fieldSetName;
        this.uiProps.customFields = change.fields.filter(
          (item) => item.isDefault === false
        );
        if (
          Array.isArray(this.uiProps.customFields) &&
          this.uiProps.customFields.length
        ) {
          this.form.customFieldKey =
            this.uiProps.customFields[0].customFieldKey;
        }
      }
    },
  },
  methods: {
    confirmSave() {
      this.resetErrors();
      if (this.validate()) {
        this.save();
      }
    },
    save: function () {
      this.$emit(
        "update",
        this.customFieldSet.fieldSetKey,
        this.form.customFieldKey
      );
      this.$refs.close.click();
    },
    validate: function () {
      if (this.form.customFieldKey == "null") {
        this.uiProps.errors.field.valid = false;
        this.uiProps.errors.field.message = "Select a custom field";
        return false;
      } else {
        return true;
      }
    },
    resetErrors() {
      this.uiProps.errors = initialState().uiProps.errors;
    },
  },
};
</script>
